import Notification from 'components/shared/Notification';
import React from 'react';
import { toast } from 'react-toastify';
import { isRemoteMode } from 'utils/ApiErrorHandler';

export default function ApiToastHandle(errStatus, errText, type) {
  // const isAuthenticated = Cookies.get('userToken');
  // process.env.REACT_APP_IS_REMOTE_MODE === "true"
  // eslint-disable-next-line no-self-compare
  if (isRemoteMode("TRUE_REMOTE_MODE") && errStatus === undefined && errText === undefined) {
    return toast(<Notification status={410} text={'In remote mode, you can not access this feature.'} type={'info'} />);
  }
  switch (true) {
    case errStatus === 401:
      if (window.location.pathname.includes('login')) {
        if (errText === 'Authentication credentials were not provided.') return '';
        return toast(
          <Notification
            status={errStatus}
            text={errText || 'An unexpected issue has occurred, please try again'}
            type={type || 'error'}
          />
        );
      }
      return '';
    // case errStatus < 400:
    //   return toast(<Notification status={errStatus} type={type || 'success'} text={errText} />);
    case errStatus === 404:
      return toast(<Notification status={errStatus} type={type || 'error'} text="Resource Not Found!" />);
    case errStatus === 500:
      return toast(
        <Notification status={errStatus} type={type || 'error'} text="Something went wrong with the server!" />
      );
    // case errStatus >= 500:
    //   return toast(
    //     <Notification status={errStatus} type={type || 'error'} text={'Something went wrong with the server'} />
    //   );
    // case errStatus > 400 && errStatus < 500 && errText !== null && isAuthenticated:
    //   return Object?.entries(errText).map((err) =>
    //     toast(<Notification status={errStatus || 500} type={type || 'success'} text={err?.[1]} />)
    //   );
    default:
      if (errStatus === 401 || errText === 'Authentication credentials were not provided.') return '';
      return toast(
        <Notification
          status={errStatus}
          text={errText || 'An unexpected issue has occurred, please try again'}
          type={type || 'error'}
        />
      );
  }
}
